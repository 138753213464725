import { Button, Row, Table, Tooltip, Typography } from "antd";
import { WidgetType } from "@/models/enum/widget-types";
import Chart, { GoogleChartWrapperChartType } from "react-google-charts";
import { useEffect, useRef } from "react";
import { useForceUpdate } from "observable-hooks";
import { SettingOutlined } from "@ant-design/icons";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import "./widget.less";
import { TableSettings } from "@/models/widget-settings";
import { ColumnsType } from "antd/lib/table";
import useDimension from "@/utils/use-dimension";
import Loader from "@/app/components/loader";
import { CSVLink } from "react-csv"

const { Title, Text } = Typography;

export function Widget(props: {
  name: string;
  type: string;
  settings: any;
  value: any;
}) {
  const isEditing = useObservable(dashboardService.isEditing);
  const isFetching = useObservable(dashboardService.isFetchingValues);

  const getWidget = () => {
    if (!props.value || isFetching) {
      return <Loader />;
    }
    switch (props.type) {
      case WidgetType.Card:
        return <Text style={{ fontSize: "25px" }}>{props.value}</Text>;
      case WidgetType.Line:
      case WidgetType.Pie:
      case WidgetType.Bar:
        return <GoogleChart type={props.type} value={props.value} />;
      case WidgetType.Table:
        return <TableChart settings={Object.assign(new TableSettings(), JSON.parse(props.settings)) as TableSettings} value={props.value} />
    }
  };

  return (
    <div className="widget-container">
      {isEditing && (
        <div className="settings">
          <Tooltip title="Filter settings">
            <Button
              icon={
                <SettingOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    alert("click");
                  }}
                />
              }
              size="small"
              shape="circle"
            />
          </Tooltip>
        </div>
      )}
      <div className="header">
        <WidgetTitle title={props.name} />
      </div>
      <div className="body">{getWidget()}</div>
    </div>
  );
}

function WidgetTitle(props: { title: string }) {
  return <Title level={4}>{props.title}</Title>;
}

function GoogleChart(props: { type: WidgetType; value: any }) {
  const divRef = useRef(null);
  const resizeObserverRef = useRef<any>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach(() => {
        forceUpdate();
      });
    });
    if (divRef.current) resizeObserverRef.current.observe(divRef.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [divRef, forceUpdate]);

  const widgetTypeToChartType = (): GoogleChartWrapperChartType | undefined => {
    switch (props.type) {
      case WidgetType.Line:
        return "Line";
      case WidgetType.Bar:
        return "Bar";
      case WidgetType.Pie:
        return "PieChart";
    }
    return undefined;
  };

  const chartType = widgetTypeToChartType();

  if (!chartType) {
    return <Text>Unsupported chart type: {props.type}</Text>;
  }

  return (
    <div ref={divRef} style={{ width: "100%", height: "100%" }}>
      <Chart
        chartType={chartType}
        width="100%"
        height="100%"
        data={props.value}
        options={{ pieHole: 0.4 }}
      />
    </div>
  );
}

function TableChart(props: { value: any, settings: TableSettings; }) {
  const divRef = useRef(null);
  const { height } = useDimension(divRef);
  const innerColumns = props.settings.columns.filter(col => !!col.nested);

  const computeColumns = () => {
    return props.settings.columns.filter(col => !col.nested).map(col => ({
      title: col.title,
      key: col.key,
      dataIndex: col.key,
      width: col.width,
      align: col.align,
      sorter: col.sortable ? (a, b) => {
        const keyA = a[col.key].toString();
        const keyB = b[col.key].toString();

        if (keyA.length !== keyB.length) {
          return keyA.length - keyB.length;
        }
        for (let i = 0; i < keyA.length; i++) {
          const compare = keyA[i].localeCompare(keyB[i], undefined, { numeric: true })
          if (compare !== 0) {
            return compare;
          }
        }
        return 0;
      } : undefined
    })) as ColumnsType<any>
  }

  const computeExportHeaders = () => {
    return props.settings.columns.map(col => ({
      label: col.title,
      key: col.key
    }))
  }

  const expandedRowRender = (record: any) => {
    if (innerColumns.length === 0) return null;

    return (
      <div className="expanded-row-block">
        {
          innerColumns
            .map(col => {
              return <><Title level={5}>
                {col.title}
              </Title>
                <Row>
                  {record[col.key] === "" ? "-" : record[col.key]}
                </Row>
              </>
            })
        }
      </div>
    );
  };

  return (
    <div ref={divRef} style={{ width: "100%", height: "100%" }}>
      <CSVLink
        className="export-button"
        filename={"export_table.csv"}
        headers={computeExportHeaders()}
        data={props.value}
      >
        Export to CSV
      </CSVLink>
      <Table
        columns={computeColumns()}
        dataSource={props.value}
        rowKey="_id"
        size="small"
        scroll={{ y: height - 38.91, x: props.settings.columns.reduce((previous, current) => previous + current.width, 0) }}
        pagination={props.settings.pagination.enabled ? { pageSize: props.settings.pagination.pageSize } : false}
        expandable={{ expandedRowRender, showExpandColumn: innerColumns.length !== 0 }}
      />
    </div>
  );
}